import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Component({
  selector: 'an-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit {
  @Output() onCloseModal = new EventEmitter();
  @Input() backgroundColor!: string;
  @Input() largeCloseIcon = false;
  isHoursModalOpened = false;
  isMobile = false;
  color!: string;
  isLargeIcon = false;

  constructor() { }

  ngOnInit(): void {
    this.color = this.backgroundColor || 'white';

    const media = window.matchMedia('(min-width: 768px)');
    const handleMedia = (e: any) => {
      this.isLargeIcon = this.largeCloseIcon && e.matches;
    };

    handleMedia(media);
    media.addEventListener('change', handleMedia);

    window.addEventListener('message', e => {
      if (e.data.type === 'wbyc_open_hours_modal') {
        this.isHoursModalOpened = e.data.opened;
      }
    });

    this.resize();
  }

  @HostListener('window:resize')
    resize() {
      this.isMobile = window.innerWidth < 814;
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { AnVDPHeartIconComponent } from "./an-vdp-heart-icon.component";


@NgModule({
  declarations: [
    AnVDPHeartIconComponent
  ],
  imports: [
    CommonModule,
    BrowserModule
  ],
  exports: [
    AnVDPHeartIconComponent
  ]
})
export class AnVDPHeartIconModule { }

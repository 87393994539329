import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'an-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss']
})
export class TextAreaFieldComponent {

  @Input()
  label!: string;

  @Input()
  placeholder!: string;

  @Input()
  requiredErrorMessage!: string;

  @Input()
  invalidErrorMessage!: string;

  @Input()
  maxlength = 25;

  @Input()
  mask!: string;

  @Input()
  textareaControl!: FormControl;

  @Input()
  invalidCharacters: string = '';

  @Output()
  onKeydown = new EventEmitter<any>();

  @Input()
  rows = -1;

  @Input()
  resize = false;

  clear() {
    this.textareaControl.setValue('');
    this.textareaControl.markAsPristine()
  }

  get hasError() {
    return this.textareaControl.errors && !this.textareaControl.pristine;
  }

  keyPress(e: any) {
    if (this.invalidCharacters.split('').includes(e.key)) {
      e.preventDefault();
      return;
    }
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LeadFormModalLibComponent } from './leadform-modal.component';
import { SafeUrlPipeModal } from './pipes/safe-url.pipe';
import { AnModalModule,AnModalService, MODAL_DATA } from '@autonation/dt-an-components-lib/an-modal';
import { LeadFormModalHostComponent } from './leadform-modal-host/leadform-modal-host.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { CommaSeparatorPipe } from './pipes/comma-separator.pipe';
import { OfferLeadButtonComponent } from './an-offer-formfields/offer-button/offer-button.component';
import { OfferInputEmailComponent } from './an-offer-formfields/offer-input-email/offer-input-email.component';
import { OfferInputFieldComponent } from './an-offer-formfields/offer-input-field/offer-input-field.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { OfferTooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    LeadFormModalLibComponent,
    SafeUrlPipeModal,
    LeadFormModalHostComponent,
    SuccessModalComponent,
    CommaSeparatorPipe,
    OfferLeadButtonComponent,
    OfferInputEmailComponent,
    OfferInputFieldComponent,
    OfferTooltipComponent
  ],
  imports: [
    CommonModule,
    AnModalModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot() 
  ],
  exports: [
    LeadFormModalHostComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [DatePipe, CommaSeparatorPipe]
})
export class LeadFormModule { 
  constructor() {
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'an-vdp-heart-icon',
  templateUrl: './an-vdp-heart-icon.component.html',
  styleUrls: ['./an-vdp-heart-icon.component.scss']
})
export class AnVDPHeartIconComponent implements OnInit {
  @Output() onClickHeartIcon = new EventEmitter<boolean>();

  @Input() state = false;
  @Input() id!: string;
  @Input() ariaLabel!: string;
  @Input() newIcon!: boolean;
  @Input() iconWidth!: string;
  @Input() newVdpIcon!:boolean;
  @Input() isLoggedIn!: boolean;
  showHeart: boolean = false;
  saved: boolean = false;
  get width() {
    return this.iconWidth || '30px';
  }
  @Input() vin!: any; 
  @Input() iconHeight!: string;
  get height() {
    return this.iconHeight || '30px';
  }

  constructor() { }

  ngOnInit(): void {
    
    document.addEventListener('__isSavedtoFav__', (eventData: any) => {
      console.log(eventData,'eventData')
      this.saved = eventData.detail === '1';
    });
        console.log('isLoggedIn:',this.isLoggedIn,'saved:',this.saved);
  }

  clickHeart() {
    this.onClickHeartIcon.emit(this.state);
  }

  getCookieValue(cookieName: string): string | null {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [name, value] = cookie.split('=').map(c => c.trim());
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null;
  }
  


}

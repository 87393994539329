import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MODAL_DATA } from '../constants';
import { AnModalService } from '../an-modal.service';

@Component({
  selector: 'lib-wbyc-modal',
  templateUrl: './wbyc-modal.component.html',
  styleUrls: ['./wbyc-modal.component.scss']
})
export class WbycModalComponent implements OnInit {

  closeSize: 24 | 32 = 24;
  iFrameLoaded = false;
  hasScroll = true;
  isHoursModalOpened = false;
  isMobile = true;

  constructor(@Inject(MODAL_DATA) public data: any, private modalService: AnModalService) { }

  ngOnInit(): void {
    window.addEventListener('message', e => {
      if (e.data.type === 'wbyc_is_confirmation_page') {
        this.hasScroll = e.data.value;
      }
    });

    window.addEventListener('message', e => {
      if (e.data.type === 'wbyc_open_hours_modal') {
        this.isHoursModalOpened = e.data.opened;
      }
    });

    this.resize();
  }


  onLoadIframe(e: any) {
    if (e.target.src) {
      this.iFrameLoaded = true;
    }
  }

  close() {
    this.modalService.close();
  }

  @HostListener('window:resize')
  resize() {
    this.closeSize = window.innerWidth < 768 ? 24 : 32;
    this.isMobile = window.innerWidth < 814;
  }

}

<div class="an-toast" role="alert" aria-live="assertive" aria-atomic="true" [@flyInOut]="transitionState"
  [ngStyle]="{'background-color': color}" [ngClass]="{
    'an-toast--padding': !showActionText && actionText,
    'an-toast-lower--padding': actionText && actionText === 'LowerPrice',
    'an-toast-container-v3': isVersion3
  }" *ngIf="showToast">

  <div class="an-toast__content" [ngClass]="{
    'an-toast__content-max-width': showActionText,
    'an-toast-v3-reverse': isVersion3Reversed,
    'an-toast__content-v3': isVersion3 && !isVersion3Reversed,
    'an-toast-lower-content-v3': actionText && actionText === 'LowerPrice'
  }">

    <div class="an-toast__icon" [ngClass]="{
      'an-toast__icon-v3-reverse': isVersion3Reversed
    }">
      <div class="an-icon" [ngClass]="{
        'an-icon-v3-reverse': isVersion3Reversed,
        'an-icon-v3': isVersion3 && !isVersion3Reversed,
        'an-lower-icon': actionText && actionText === 'LowerPrice'
      }">

        <svg class="an-icon__check" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 13"
          *ngIf="actionText && !isVersion3">
          <path id="Path_272983" data-name="Path 272983"
            d="M110.635,77.065a1.46,1.46,0,0,1,0-2.09,1.523,1.523,0,0,1,2.127,0l2.885,2.835,6.86-8.719a1.518,1.518,0,0,1,2.1-.266,1.457,1.457,0,0,1,.271,2.067l-7.834,9.957a1.515,1.515,0,0,1-2.322.235l-4.09-4.019Z"
            transform="translate(-110.194 -68.517)" [ngStyle]="{ fill: color }"></path>
        </svg>

        <svg class="an-icon__cross" width="19" height="19" viewBox="0 0 1024 1024" version="1.1"
          (click)="showToast=false" xmlns="http://www.w3.org/2000/svg" *ngIf="!actionText || isVersion3Reversed">
          <path
            d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"
            [ngStyle]="{ fill: isVersion3 ? '#FFFFFF' : color }" />
        </svg>

        <svg class="an-icon__check-v3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
        *ngIf="(actionText && actionText == 'SaveSearchV3') && isVersion3 && !isVersion3Reversed">
          <path d="M20 6L9 17L4 12" stroke="#45B167" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg class="an-icon__check-v3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
        *ngIf="(actionText && actionText == 'DeleteSearchV3') && isVersion3 && !isVersion3Reversed">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2 5.75C2 5.33579 2.33579 5 2.75 5H20.75C21.1642 5 21.5 5.33579 21.5 5.75C21.5 6.16421 21.1642 6.5 20.75 6.5H2.75C2.33579 6.5 2 6.16421 2 5.75Z"
            fill="#EAEAEA" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.75 2.5C9.41848 2.5 9.10054 2.6317 8.86612 2.86612C8.6317 3.10054 8.5 3.41848 8.5 3.75V5H15V3.75C15 3.41848 14.8683 3.10054 14.6339 2.86612C14.3995 2.6317 14.0815 2.5 13.75 2.5H9.75ZM16.5 5V3.75C16.5 3.02065 16.2103 2.32118 15.6945 1.80546C15.1788 1.28973 14.4793 1 13.75 1H9.75C9.02065 1 8.32118 1.28973 7.80546 1.80546C7.28973 2.32118 7 3.02065 7 3.75V5H4.75C4.33579 5 4 5.33579 4 5.75V19.75C4 20.4793 4.28973 21.1788 4.80546 21.6945C5.32118 22.2103 6.02065 22.5 6.75 22.5H16.75C17.4793 22.5 18.1788 22.2103 18.6945 21.6945C19.2103 21.1788 19.5 20.4793 19.5 19.75V5.75C19.5 5.33579 19.1642 5 18.75 5H16.5ZM5.5 6.5V19.75C5.5 20.0815 5.6317 20.3995 5.86612 20.6339C6.10054 20.8683 6.41848 21 6.75 21H16.75C17.0815 21 17.3995 20.8683 17.6339 20.6339C17.8683 20.3995 18 20.0815 18 19.75V6.5H5.5Z"
            fill="#EAEAEA" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z"
            fill="#EAEAEA" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M9.75 10C10.1642 10 10.5 10.3358 10.5 10.75V16.75C10.5 17.1642 10.1642 17.5 9.75 17.5C9.33579 17.5 9 17.1642 9 16.75V10.75C9 10.3358 9.33579 10 9.75 10Z"
            fill="#EAEAEA" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 25" fill="none"
        *ngIf="(actionText && actionText === 'LowerPrice')">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7648 4.02174C13.9427 3.2099 11.9071 3.00878 9.96148 3.44838C8.01583 3.88797 6.2644 4.94473 4.96841 6.46104C3.67242 7.97734 2.9013 9.87197 2.77005 11.8623C2.63881 13.8527 3.15447 15.8322 4.24014 17.5055C5.32581 19.1789 6.92331 20.4565 8.79439 21.1477C10.6655 21.839 12.7099 21.9069 14.6227 21.3414C16.5355 20.7758 18.2143 19.607 19.4086 18.0094C20.603 16.4118 21.2489 14.4709 21.25 12.4762V11.5567C21.25 11.1425 21.5858 10.8067 22 10.8067C22.4142 10.8067 22.75 11.1425 22.75 11.5567V12.4767C22.7487 14.7948 21.998 17.0509 20.61 18.9076C19.222 20.7642 17.271 22.1225 15.048 22.7798C12.825 23.4371 10.4491 23.3581 8.27456 22.5548C6.10006 21.7514 4.2435 20.2667 2.98178 18.3219C1.72006 16.3772 1.12078 14.0768 1.2733 11.7636C1.42583 9.45051 2.322 7.24865 3.82815 5.48645C5.3343 3.72426 7.36974 2.49614 9.6309 1.98526C11.8921 1.47438 14.2578 1.70811 16.3753 2.6516C16.7536 2.82019 16.9237 3.26357 16.7551 3.64192C16.5865 4.02028 16.1431 4.19033 15.7648 4.02174Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5301 3.94608C22.8231 4.23882 22.8233 4.7137 22.5306 5.00674L12.5306 15.0167C12.39 15.1575 12.1992 15.2366 12.0002 15.2367C11.8012 15.2367 11.6104 15.1577 11.4697 15.017L8.46968 12.017C8.17678 11.7241 8.17678 11.2492 8.46968 10.9563C8.76257 10.6634 9.23744 10.6634 9.53034 10.9563L11.9997 13.4257L21.4694 3.94661C21.7622 3.65357 22.237 3.65333 22.5301 3.94608Z" fill="white"/>
        </svg>
      </div>
    </div>

    <div class="an-content">
      <div class="an-content__title" [ngClass]="{
        'an-content__title-v3': isVersion3,
        'an-content-lower__title-v3': actionText && actionText === 'LowerPrice'
      }"> {{ title }} </div>
      <div class="an-content__message" *ngIf="message" [ngClass]="{
        'an-content__message-v3': isVersion3, 'an-content-lower__message-v3': actionText && actionText === 'LowerPrice'
      }"> {{ message }} </div>
    </div>
    <svg class="an-lower-close" (click)="showToast=false" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"
    *ngIf="(actionText && actionText === 'LowerPrice')">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24.4767C5.37258 24.4767 0 19.1041 0 12.4767C0 5.84927 5.37258 0.476685 12 0.476685C18.6274 0.476685 24 5.84927 24 12.4767C24 19.1041 18.6274 24.4767 12 24.4767Z" fill="#333333"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7534 15.0277C16.0853 15.3598 16.0853 15.8963 15.7534 16.2284C15.5878 16.394 15.3705 16.4771 15.153 16.4771C14.9356 16.4771 14.7183 16.394 14.5527 16.2284L12.0013 13.6768L9.44986 16.2284C9.28431 16.394 9.06695 16.4771 8.84961 16.4771C8.63212 16.4771 8.41475 16.394 8.24919 16.2284C7.91726 15.8963 7.91726 15.3598 8.24919 15.0277L10.8007 12.4763L8.24919 9.92486C7.91726 9.59278 7.91726 9.05624 8.24919 8.72417C8.58123 8.39224 9.11778 8.39224 9.44986 8.72417L12.0013 11.2757L14.5527 8.72417C14.8848 8.39224 15.4213 8.39224 15.7534 8.72417C16.0853 9.05625 16.0853 9.59279 15.7534 9.92486L13.2018 12.4763L15.7534 15.0277Z" fill="white"/>
    </svg>
  </div>

  <ng-container *ngIf="showActionText">
    <div class="an-toast__action" [attr.aria-label]="actionText" *ngIf="actionText" (click)="actionClick($event)">
      <span class="an-action__text"> {{ actionText }} </span>
    </div>
  </ng-container>

</div>
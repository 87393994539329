import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonStyles, LabelStyles } from '../../models/appointment-models';

@Component({
  selector: 'an-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {

  @Input()
  label!: string;

  @Input()
  showIcon!: boolean;

  @Input()
  buttonStyles!: ButtonStyles;

  @Input()
  labelStyles!: LabelStyles;

  @Input()
  type!: string;

  @Output()
  onButtonClick = new EventEmitter();

  constructor() { 

  }

  ngOnInit(): void {
  }

  buttonClick() {
    this.onButtonClick.emit();
  }

}

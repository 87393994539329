<div class="an-input-field form-group">
  <label [for]="'id-' + label" class="an-input-field__label"> {{ label }} </label>
  <ng-content #help></ng-content>
  <textarea [rows]="rows != -1 ? rows : ''" [ngClass]="{'an-inpu-field-rows': rows != -1}" [ngStyle]="{'resize': resize ? 'auto' : 'none'}" [maxlength]="maxlength" class="an-input-field__control text-area form-control" [id]="'id-' + label"
    [placeholder]="placeholder" [mask]="mask || ''" [formControl]="textareaControl" (keypress)="keyPress($event)" (keydown)="onKeydown.emit($event)"></textarea>
  <a href="javascript:void(0)" class="an-input-field__clear" [ngClass]="{'an-inpu-field-rows': rows != -1}" *ngIf="hasError" (click)="clear()"></a>
  <div class="an-input-field__error-message" *ngIf="hasError">
    {{ textareaControl.hasError('required') ? requiredErrorMessage : invalidErrorMessage }}
  </div>
  <ng-content #autocomplete></ng-content>
</div>
import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ApptDate, ButtonStyles, LabelStyles, State } from '@autonation/dt-an-components-lib/an-appointment';
// import { ZipcodeService } from '@autonation/dt-an-zipcodewidget-lib';
// import { UpdateZipcodeResponse } from '@autonation/dt-an-zipcodewidget-lib/models/updateZIpcodeResponse';
import { Observable, map } from 'rxjs';

// export class ZipcodeValidator {
//   static createValidator(zipcodeService: ZipcodeService): AsyncValidatorFn {
//     return (control: AbstractControl): Observable<any> => {
//       return zipcodeService.updateZipcode(control.value).pipe(
//         map((result: UpdateZipcodeResponse) => result.Success ? null : {invalidAsync: true})
//       );
//     };
//   }
// }

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  appointmentFormGroup!: FormGroup;

  dateTitle = 'Select Appointment Date';
  dates!: string[];

  timeTitle = 'Select Appointment Time';
  hours!: string[];

  states!: State[];

  myButtonLabel = 'My Custom Button';

  myButtonStyles: ButtonStyles = {
    background: '#000'
  };

  myLabelStyles: LabelStyles = {
    color: '#fff'
  };

  myButtonStylesWIthIcon: ButtonStyles = {
    background: 'linear-gradient(90deg, #AD104C 0%, #E61464 100%)'
  };

  myLabelStylesWithIcon: LabelStyles = {
    color: '#fff'
  };


  constructor(
    private fb: FormBuilder
    // private zipcodeService: ZipcodeService
  ) { }

  ngOnInit(): void {
    this.appointmentFormGroup = this.fb.group({
      date: ['03/26/2024'],
      time: [''],
      firstName: ['', [Validators.required]],
      lastName: ['jose', [Validators.required]],
      email: ['jose@mail.com', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
      phone: ['7866231317', [Validators.required, Validators.pattern(/([1-9])(\d{3})(\d{4})/)]],
      zipCode: ['33068', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
      // zipCode: ['33068', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)], ZipcodeValidator.createValidator(this.zipcodeService)],
      state: ['State', [Validators.pattern(/[A-Z][A-Z]/)]]
    });

    this.dates = this.CalendarDates.map(c => c.date);
    this.hours = this.CalendarDates[0].hours;

    this.appointmentFormGroup.get('time')?.setValue(this.hours[0])

    this.states = this.States.map(v => {
      return {
        details: v.Details,
        description: v.Description,
        value: v.Value
      };
    });

    this.emailControl.disable();
  }

  myButtonClick() {
    alert('Clicking my custom button ...');
  }

  myButtonWithIconClick() {
    alert('Clicking my custom button with icon ...');
  }

  get dateControl() {
    return this.appointmentFormGroup.get('date') as FormControl;
  }

  get timeControl() {
    return this.appointmentFormGroup.get('time') as FormControl;
  }

  get firstNameControl() {
    return this.appointmentFormGroup.get('firstName') as FormControl;
  }


  get lastNameControl() {
    return this.appointmentFormGroup.get('lastName') as FormControl;
  }

  get phoneControl() {
    return this.appointmentFormGroup.get('phone') as FormControl;
  }

  get zipCodeControl() {
    return this.appointmentFormGroup.get('zipCode') as FormControl;
  }

  get emailControl() {
    return this.appointmentFormGroup.get('email') as FormControl;
  }

  get stateControl() {
    return this.appointmentFormGroup.get('state') as FormControl;
  }

  disclaimerControl = new FormControl('', [Validators.required]);
  priceControl = new FormControl('', [Validators.required]);


  clickHelp() {
    alert('email click help ...');
  }

  onChangeDate(date: ApptDate) {
    this.hours = this.CalendarDates
      .find(c => (new Date(c.date).getDate() == date.day))?.hours || [];
    
    this.timeControl.setValue(this.hours[0])
  }

  CalendarDates = [
    {
      "date": "03/25/2024",
      "hours": [
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM",
        "06:15 PM",
        "06:30 PM",
        "06:45 PM",
        "07:00 PM",
        "07:15 PM",
        "07:30 PM",
        "07:45 PM",
        "08:00 PM"
      ]
    },
    {
      "date": "03/26/2024",
      "hours": [
        "05:00 AM",
        "10:15 AM",
        "10:30 AM",
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM",
        "06:15 PM",
        "06:30 PM",
        "06:45 PM",
        "07:00 PM",
        "07:15 PM",
        "07:30 PM",
        "07:45 PM",
        "08:00 PM"
      ]
    },
    {
      "date": "03/27/2024",
      "hours": [
        "10:00 AM",
        "10:15 AM",
        "10:30 AM",
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM",
        "06:15 PM",
        "06:30 PM",
        "06:45 PM",
        "07:00 PM",
        "07:15 PM",
        "07:30 PM",
        "07:45 PM",
        "08:00 PM"
      ]
    },
    {
      "date": "03/28/2024",
      "hours": [
        "10:00 AM",
        "10:15 AM",
        "10:30 AM",
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM",
        "06:15 PM",
        "06:30 PM",
        "06:45 PM",
        "07:00 PM",
        "07:15 PM",
        "07:30 PM",
        "07:45 PM",
        "08:00 PM"
      ]
    },
    {
      "date": "03/29/2024",
      "hours": [
        "10:00 AM",
        "10:15 AM",
        "10:30 AM",
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM",
        "06:15 PM",
        "06:30 PM",
        "06:45 PM",
        "07:00 PM",
        "07:15 PM",
        "07:30 PM",
        "07:45 PM",
        "08:00 PM"
      ]
    },
    {
      "date": "03/30/2024",
      "hours": [
        "10:00 AM",
        "10:15 AM",
        "10:30 AM",
        "10:45 AM",
        "11:00 AM",
        "11:15 AM",
        "11:30 AM",
        "11:45 AM",
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM",
        "06:15 PM",
        "06:30 PM",
        "06:45 PM",
        "07:00 PM",
        "07:15 PM",
        "07:30 PM",
        "07:45 PM",
        "08:00 PM"
      ]
    },
    {
      "date": "03/31/2024",
      "hours": [
        "12:00 PM",
        "12:15 PM",
        "12:30 PM",
        "12:45 PM",
        "01:00 PM",
        "01:15 PM",
        "01:30 PM",
        "01:45 PM",
        "02:00 PM",
        "02:15 PM",
        "02:30 PM",
        "02:45 PM",
        "03:00 PM",
        "03:15 PM",
        "03:30 PM",
        "03:45 PM",
        "04:00 PM",
        "04:15 PM",
        "04:30 PM",
        "04:45 PM",
        "05:00 PM",
        "05:15 PM",
        "05:30 PM",
        "05:45 PM",
        "06:00 PM"
      ]
    }
  ];

  leadFormLabels = {
    firstName: {
      label: 'First Name*',
      placeholder: 'First Name',
      errors: {
        required: 'Please enter your first name'
      }
    },
    lastName: {
      label: 'Last Name*',
      placeholder: 'Last Name',
      errors: {
        required: 'Please enter your last name'
      }
    },
    email: {
      label: 'Email Address*',
      placeholder: 'Email Address',
      errors: {
        required: 'Please enter your email',
        invalid: 'Please enter valid email'
      }
    },
    phone: {
      label: 'Phone Number*',
      placeholder: 'Phone Number',
      errors: {
        required: 'Enter phone number',
        invalid: 'Valid phone number'
      }
    },
    zipCode: {
      label: 'Zip Code*',
      placeholder: 'Zip Code',
      errors: {
        required: 'Enter ZIP',
        invalid: 'Valid ZIP'
      }
    },
    disclaimer: {
      label: 'Disclaimer',
      placeholder: 'Enter disclaimer',
      requiredErrorMessage: 'This field is required'
    },
    price: {
      label: 'Price',
      placeholder: 'Enter price',
      requiredErrorMessage: 'This field is required'
    }
  };

  States = [
    {
      "Details": "Alaska",
      "Description": "AK",
      "Value": "AK"
    },
    {
      "Details": "Alabama",
      "Description": "AL",
      "Value": "AL"
    },
    {
      "Details": "Arkansas",
      "Description": "AR",
      "Value": "AR"
    },
    {
      "Details": "American Samoa",
      "Description": "AS",
      "Value": "AS"
    },
    {
      "Details": "Arizona",
      "Description": "AZ",
      "Value": "AZ"
    },
    {
      "Details": "California",
      "Description": "CA",
      "Value": "CA"
    },
    {
      "Details": "Colorado",
      "Description": "CO",
      "Value": "CO"
    },
    {
      "Details": "Connecticut",
      "Description": "CT",
      "Value": "CT"
    },
    {
      "Details": "District of Columbia",
      "Description": "DC",
      "Value": "DC"
    },
    {
      "Details": "Delaware",
      "Description": "DE",
      "Value": "DE"
    },
    {
      "Details": "Florida",
      "Description": "FL",
      "Value": "FL"
    },
    {
      "Details": "Georgia",
      "Description": "GA",
      "Value": "GA"
    },
    {
      "Details": "Guam",
      "Description": "GU",
      "Value": "GU"
    },
    {
      "Details": "Hawaii",
      "Description": "HI",
      "Value": "HI"
    },
    {
      "Details": "Iowa",
      "Description": "IA",
      "Value": "IA"
    },
    {
      "Details": "Idaho",
      "Description": "ID",
      "Value": "ID"
    },
    {
      "Details": "Illinois",
      "Description": "IL",
      "Value": "IL"
    },
    {
      "Details": "Indiana",
      "Description": "IN",
      "Value": "IN"
    },
    {
      "Details": "Kansas",
      "Description": "KS",
      "Value": "KS"
    },
    {
      "Details": "Kentucky",
      "Description": "KY",
      "Value": "KY"
    },
    {
      "Details": "Louisiana",
      "Description": "LA",
      "Value": "LA"
    },
    {
      "Details": "Massachusetts",
      "Description": "MA",
      "Value": "MA"
    },
    {
      "Details": "Maryland",
      "Description": "MD",
      "Value": "MD"
    },
    {
      "Details": "Maine",
      "Description": "ME",
      "Value": "ME"
    },
    {
      "Details": "Michigan",
      "Description": "MI",
      "Value": "MI"
    },
    {
      "Details": "Minnesota",
      "Description": "MN",
      "Value": "MN"
    },
    {
      "Details": "Missouri",
      "Description": "MO",
      "Value": "MO"
    },
    {
      "Details": "Northern Marianas",
      "Description": "MP",
      "Value": "MP"
    },
    {
      "Details": "Mississippi",
      "Description": "MS",
      "Value": "MS"
    },
    {
      "Details": "Montana",
      "Description": "MT",
      "Value": "MT"
    },
    {
      "Details": "North Carolina",
      "Description": "NC",
      "Value": "NC"
    },
    {
      "Details": "North Dakota",
      "Description": "ND",
      "Value": "ND"
    },
    {
      "Details": "Nebraska",
      "Description": "NE",
      "Value": "NE"
    },
    {
      "Details": "New Hampshire",
      "Description": "NH",
      "Value": "NH"
    },
    {
      "Details": "New Jersey",
      "Description": "NJ",
      "Value": "NJ"
    },
    {
      "Details": "New Mexico",
      "Description": "NM",
      "Value": "NM"
    },
    {
      "Details": "Nevada",
      "Description": "NV",
      "Value": "NV"
    },
    {
      "Details": "New York",
      "Description": "NY",
      "Value": "NY"
    },
    {
      "Details": "Ohio",
      "Description": "OH",
      "Value": "OH"
    },
    {
      "Details": "Oklahoma",
      "Description": "OK",
      "Value": "OK"
    },
    {
      "Details": "Oregon",
      "Description": "OR",
      "Value": "OR"
    },
    {
      "Details": "Pennsylvania",
      "Description": "PA",
      "Value": "PA"
    },
    {
      "Details": "Puerto Rico",
      "Description": "PR",
      "Value": "PR"
    },
    {
      "Details": "Rhode Island",
      "Description": "RI",
      "Value": "RI"
    },
    {
      "Details": "South Carolina",
      "Description": "SC",
      "Value": "SC"
    },
    {
      "Details": "South Dakota",
      "Description": "SD",
      "Value": "SD"
    },
    {
      "Details": "Tennessee",
      "Description": "TN",
      "Value": "TN"
    },
    {
      "Details": "Texas",
      "Description": "TX",
      "Value": "TX"
    },
    {
      "Details": "U.S. Minor Outlying Islands",
      "Description": "UM",
      "Value": "UM"
    },
    {
      "Details": "Utah",
      "Description": "UT",
      "Value": "UT"
    },
    {
      "Details": "Virginia",
      "Description": "VA",
      "Value": "VA"
    },
    {
      "Details": "Virgin Islands",
      "Description": "VI",
      "Value": "VI"
    },
    {
      "Details": "Vermont",
      "Description": "VT",
      "Value": "VT"
    },
    {
      "Details": "Washington",
      "Description": "WA",
      "Value": "WA"
    },
    {
      "Details": "Wisconsin",
      "Description": "WI",
      "Value": "WI"
    },
    {
      "Details": "West Virginia",
      "Description": "WV",
      "Value": "WV"
    },
    {
      "Details": "Wyoming",
      "Description": "WY",
      "Value": "WY"
    }
  ];

}

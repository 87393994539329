import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'an-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {

  @Input()
  label!: string;

  @Input()
  placeholder!: string;

  @Input()
  requiredErrorMessage!: string;

  @Input()
  invalidErrorMessage!: string;

  @Input()
  maxlength = 25;

  @Input()
  mask!: string;

  @Input()
  prefix!: string;

  @Input()
  suffix!: string;

  @Input()
  thousandSeparator!: string;

  @Input()
  separatorLimit!: string;

  @Input()
  inputControl!: FormControl;

  @Input()
  invalidCharacters: string = '';

  @Output()
  onKeydown = new EventEmitter<any>();

  clear() {
    this.inputControl.setValue('');
    this.inputControl.markAsPristine()
  }

  get hasError() {
    return this.inputControl.errors && !this.inputControl.pristine;
  }

  keyPress(e: any) {
    if (this.invalidCharacters.split('').includes(e.key)) {
      e.preventDefault();
      return;
    }
  }

}

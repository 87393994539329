import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AnModalService, ModalConfig } from '@autonation/dt-an-components-lib/an-modal';
import { AnToastService } from 'projects/dt-an-components-lib/an-toast/src/an-toast.service';
import { AnAuthenticationModalService } from 'projects/dt-an-components-lib/authentication-modal/public-api';
import { IframeComponent } from '../../iframe/iframe.component';
import { HoursComponent } from '../../hours/hours.component';
import { AnVerifyEmailModalService } from '@autonation/dt-an-components-lib/verify-email-modal/public-api';
import { AnAddVehicleService } from '@autonation/dt-an-components-lib/an-add-vehicle-modal/src/add-vehicle.service';
import { AnCompareVehicleService } from '@autonation/dt-an-components-lib/an-compare-vehicle-modal';
import { mock } from '@autonation/dt-an-components-lib/an-compare-vehicle-modal/src/compare-vehicle.mock';
import { ErrorModalMessageComponent } from '../error-modal-message/error-modal-message.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './app-index.component.html',
  styleUrls: ['./app-index.component.scss']
})
export class AppIndexComponent implements OnInit {

  toastGroup!: FormGroup;
  test = '11,235';
  test2 = '11235';

  constructor(
    private toastService: AnToastService,
    private authModalService: AnAuthenticationModalService,
    private verifyEmailModalService: AnVerifyEmailModalService,
    private addVehicleModalService: AnAddVehicleService,
    private compareVehicleModalService: AnCompareVehicleService,
    private fb: FormBuilder,
    private modalService: AnModalService
  ) {
    this.toastGroup = this.fb.group({
      color: ['green'],
      title: ['Custom text'],
      actionText: ['Click me']
    });
  }

  openSave() {
    this.toastService.save().subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openRemove() {
    this.toastService.remove({

    }).subscribe(e => {
      console.log('action clicked and close toast', e);
    });
  }

  openError() {
    this.toastService.error();
  }

  openLimit() {
    this.toastService.limit();
  }

  openExists() {
    this.toastService.alreadyExists().subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchSaveToast() {
    let config = {
      title: 'Search Saved',
      showActionText: false
    }
    this.toastService.save(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchSaveV3Toast() {
    let config = {
      title: 'Lower price unlocked!',
      showActionText: false,
      message: 'We’ve gone ahead and lowered prices on all other eligible vehicles, too',
      actionText: 'LowerPrice',
      isVersion3: true,
      isVersion3Reversed: false
    }
    this.toastService.saveSearchV3(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchDeleteToast() {
    let config = {
      color: '#333',
      title: 'Search Deleted',
      showActionText: false
    }
    this.toastService.save(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchDeleteToastV3() {
    let config = {
      title: 'Your search is deleted',
      showActionText: false,
      isVersion3: true,
      isVersion3Reversed: false
    }
    this.toastService.deleteSearchV3(config).subscribe((e: any) => {
      console.log('action clicked and close toast', e);
    });
  }

  openSaveSearchErrorToast() {
    let config = {
      title: 'Error Saving Search',
      showActionText: false
    }
    this.toastService.error(config);
  }

  openGetLowerToast() {
    this.toastService.showGetLowerToast();
  }

  openSaveSearchErrorV3Toast() {
    let config = {
      title: 'We Hit a Speed Bump!',
      message: 'There was an issue saving your search, please try again.',
      showActionText: false,
      isVersion3: true,
      isVersion3Reversed: true
    }
    this.toastService.saveSearchErrorV3(config);
  }

  openCustom() {
    this.toastService.save({
      title: 'Success',
      message: 'Your appointment was cancelled.',
      showActionText: false
    });
  }

  heartIconOn = false;

  clickHeartIcon(state: boolean) {
    console.log(state);

    this.heartIconOn = !state;
  }

  openAuthModal() {
    this.authModalService.triggerAuthModal().subscribe((doAuthOnClosing: any) => {
      if (doAuthOnClosing) {
        console.log('Navigate to Authentication Page with', doAuthOnClosing, 'mode');
        try {
          if ((window as any).auth) {
            const extraParam: string = `mg=f`;
            (window as any).auth.loginWithAuth0(doAuthOnClosing, extraParam);
          }
        } catch (e) {/* istanbul ignore next */
          console.log(e);
        }
      }
    });
  }

  getFavorites(): any {
    return {
      "Data": {
        "favoriteVehicles": [
          {
            "vin": "3FMCR9B69NRE15880",
            "vehicleStatus": "in_stock"
          },
          {
            "vin": "1J4NF4GB9AD675093",
            "vehicleStatus": "in_stock"
          }
        ],
        "favoriteVehiclesCount": 2,
        "maxFavoriteVehiclesLimit": 4
      },
      "Status": true,
      "HttpStatusCode": 200,
      "ErrorMassage": "",
      "Exception": null
    };
  }

  ngOnInit(): void {
    this.favorites = this.getFavorites();


    // timer(3000).subscribe(v => {
    //   this.vehicles.push({Vin: '1FATP8FF3N5146881', vehicleSaved: false});
    // });
  }

  favorites: any;

  loading = false;

  vehicles = [
    { vehicleSaved: false, Vin: '1G1ZC5E04CF115960' },
    { vehicleSaved: false, Vin: '3FMCR9B69NRE20853' },
    { vehicleSaved: false, Vin: '1FMDE5BH6NLB77883' },
    { vehicleSaved: false, Vin: '2FMPK4G96NBA96254' },
    { vehicleSaved: true, Vin: '3FMCR9B69NRE15880' },
    { vehicleSaved: true, Vin: 'W1KWJ6EB7NG115039' }


  ];

  setLoading(status: boolean) {
    this.loading = status;
  }

  MyGarageFavorites = {
    EnableFavorites: true,
    FavoritesMaxLimit: 30
  }

  oldSaveVehicle() {
    console.log('old save vehicle ...');

    this.vehicles[0].vehicleSaved = true;

    console.log(this.vehicles);
  }

  // modal ------------


  updateConfig = new Subject<ModalConfig>();

  openModalTop() {
    this.modalService.open(ErrorModalMessageComponent, {}, {
      maxWidth: '100%',
      alignItems: window.innerWidth > 768 ? 'flex-start' : 'flex-end',
      margin: window.innerWidth > 768 ? '20px auto' : '35px auto',
      backdropClickClose: false,
      mobileFullSize: false,
      backdropColor: 'rgba(0,0,0,0)'
    }, this.updateConfig);
  }

  @HostListener('window:resize')
  resize() {
    let align =  window.innerWidth > 768 ? 'flex-start' : 'flex-end';
    let margin =  window.innerWidth > 768 ? '20px auto' : '35px auto';
    let maxWidth = window.innerWidth > 1024 ? '696px' : '100%';

    this.updateConfig.next({
      maxWidth: maxWidth,
      alignItems: align,
      margin: margin
    });
  }

  openModal() {
    this.modalService.open(IframeComponent, {
      src: "https://www6.qaautonation.com/appointment?hid=2340&vin=JTHGZ1B20P5063037&type=1&iframe=true&srcpath=vdp",
      title: 'Appointment and Reservation',
      id: 'myIframeId'
    }, {
      maxWidth: '994px'
    }).onClosed.subscribe(_ => console.log('Appointment modal closed ...'));
  }

  openWBYCModal() {
    const url = 'https://www6.qaautonation.com/sell-my-car?iframe=true&ismodal=true&hid=2940&vin=1C4PJXAN3SW518022&mk=Jeep&cnd=NEW';

    this.modalService.openWBYCModal(url, {
      maxWidth: '1098px'
    }).subscribe(v => {
      console.log('WBYC modal closed ...')
    })
  }

  openModalHours() {
    const salesDepartment = {
      "Name": "Sales",
      "Hours": null,
      "ContactName": null,
      "ContactPhone": null,
      "ContactEmail": null,
      "DepartmentType": "Sales",
      "DetailedHours": [
        {
          "Day": 0,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 1,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 2,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 3,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 4,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 5,
          "StartTime": "9:00 AM",
          "EndTime": "9:00 PM",
          "Reoccurence": 0
        },
        {
          "Day": 6,
          "StartTime": "11:00 AM",
          "EndTime": "7:00 PM",
          "Reoccurence": 0
        }
      ],
      "Brands": null
    };

    this.modalService.open(HoursComponent, {
      department: salesDepartment
    }, {
      maxWidth: '430px'
    }).onClosed.subscribe(v => {
      console.log('closing hours modal');
    })
  }

  openSRPCompareVehicleModal() {
    let url = 'aks-sitecore.internal.qaautonation.com/common-service/comparevehicles/?vin=W1KCG2DB8NA017510|4JGGM2BB1PA008315|4JGGM2BB4PA007157&zipcode=33301&fuelTypeDetailsKey=EF&ShowCompareonVDP=false';

    const reqObj = {
      carsArray: mock.Data,
      viewMode: 'modal'
    }

    this.compareVehicleModalService.triggerCompareVehicleModal(reqObj).subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);
    });
  }

  loadVDPCompareVehicleSection() {
    let url = 'aks-sitecore.internal.qaautonation.com/common-service/comparevehicles/?vin=W1KCG2DB8NA017510&zipcode=33301&fuelTypeDetailsKey=EF&ShowCompareonVDP=true';

    const reqObj = {
      carsArray: mock.Data,
      viewMode: 'non-modal'
    }

    document.dispatchEvent(new CustomEvent("__LOAD__VEHICLE__COMPARE__SECTION__", {
      detail: reqObj,
    })
    );
  }

  openAddVehicleModal() {

    const mockData = {
      isFinishAddingFlow: false,
      vehciles: [{
        VehicleId: '11b9c819-58b1-479e-a8ba-088912bc3a74',
        Make: 'Ford',
        Model: 'Escape',
        Year: '2022',
        models: [
          {
            "id": 35192,
            "name": "Bronco"
          },
          {
            "id": 35070,
            "name": "Bronco Sport"
          },
          {
            "id": 34022,
            "name": "E-Series Cutaway"
          },
          {
            "id": 34023,
            "name": "E-Series Stripped Chassis"
          },
          {
            "id": 34441,
            "name": "E-Transit Cargo Van"
          },
          {
            "id": 34752,
            "name": "E-Transit Chassis"
          },
          {
            "id": 34753,
            "name": "E-Transit Cutaway"
          },
          {
            "id": 34789,
            "name": "EcoSport"
          },
          {
            "id": 34851,
            "name": "Edge"
          },
          {
            "id": 34526,
            "name": "Escape"
          },
          {
            "id": 34795,
            "name": "Expedition"
          },
          {
            "id": 34894,
            "name": "Expedition Max"
          },
          {
            "id": 34788,
            "name": "Explorer"
          },
          {
            "id": 35085,
            "name": "F-150"
          },
          {
            "id": 35373,
            "name": "F-150 Lightning"
          },
          {
            "id": 35129,
            "name": "F-150 Police Responder"
          },
          {
            "id": 35156,
            "name": "F-150 Special Service Vehicle"
          },
          {
            "id": 35116,
            "name": "F-53 Motorhome Stripped Chassis"
          },
          {
            "id": 35117,
            "name": "F-59 Commercial Stripped Chassis"
          },
          {
            "id": 34103,
            "name": "F-650 Pro Loader"
          },
          {
            "id": 34104,
            "name": "F-650 Pro Loader Gas"
          },
          {
            "id": 34105,
            "name": "F-650 Straight Frame"
          },
          {
            "id": 34106,
            "name": "F-650 Straight Frame Gas"
          },
          {
            "id": 34107,
            "name": "F-650 Tractor"
          },
          {
            "id": 34108,
            "name": "F-750 Straight Frame"
          },
          {
            "id": 34109,
            "name": "F-750 Straight Frame Gas"
          },
          {
            "id": 34110,
            "name": "F-750 Tractor"
          },
          {
            "id": 34705,
            "name": "Maverick"
          },
          {
            "id": 35213,
            "name": "Mustang"
          },
          {
            "id": 35133,
            "name": "Mustang Mach-E"
          },
          {
            "id": 34903,
            "name": "Police Interceptor Utility"
          },
          {
            "id": 35138,
            "name": "Ranger"
          },
          {
            "id": 34313,
            "name": "Super Duty F-250 SRW"
          },
          {
            "id": 34304,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34314,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34305,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34315,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34303,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34326,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34311,
            "name": "Super Duty F-550 DRW"
          },
          {
            "id": 34309,
            "name": "Super Duty F-600 DRW"
          },
          {
            "id": 34439,
            "name": "Transit Cargo Van"
          },
          {
            "id": 34751,
            "name": "Transit Chassis"
          },
          {
            "id": 34230,
            "name": "Transit Connect Van"
          },
          {
            "id": 34231,
            "name": "Transit Connect Wagon"
          },
          {
            "id": 34440,
            "name": "Transit Crew Van"
          },
          {
            "id": 34750,
            "name": "Transit Cutaway"
          },
          {
            "id": 34469,
            "name": "Transit Passenger Wagon"
          }
        ],
        "Trim": [],
        ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/FIAT/500X',
      },
        //   {
        //     VehicleId: '3267f98f-5724-4495-97d7-4c6b82f5f1cc',
        //     Make: 'Ford',
        //     Model: 'Escape',
        //     Year: '2022',
        //     models: [
        //       {
        //           "id": 35192,
        //           "name": "Bronco"
        //       },
        //       {
        //           "id": 35070,
        //           "name": "Bronco Sport"
        //       },
        //       {
        //           "id": 34022,
        //           "name": "E-Series Cutaway"
        //       },
        //       {
        //           "id": 34023,
        //           "name": "E-Series Stripped Chassis"
        //       },
        //       {
        //           "id": 34441,
        //           "name": "E-Transit Cargo Van"
        //       },
        //       {
        //           "id": 34752,
        //           "name": "E-Transit Chassis"
        //       },
        //       {
        //           "id": 34753,
        //           "name": "E-Transit Cutaway"
        //       },
        //       {
        //           "id": 34789,
        //           "name": "EcoSport"
        //       },
        //       {
        //           "id": 34851,
        //           "name": "Edge"
        //       },
        //       {
        //           "id": 34526,
        //           "name": "Escape"
        //       },
        //       {
        //           "id": 34795,
        //           "name": "Expedition"
        //       },
        //       {
        //           "id": 34894,
        //           "name": "Expedition Max"
        //       },
        //       {
        //           "id": 34788,
        //           "name": "Explorer"
        //       },
        //       {
        //           "id": 35085,
        //           "name": "F-150"
        //       },
        //       {
        //           "id": 35373,
        //           "name": "F-150 Lightning"
        //       },
        //       {
        //           "id": 35129,
        //           "name": "F-150 Police Responder"
        //       },
        //       {
        //           "id": 35156,
        //           "name": "F-150 Special Service Vehicle"
        //       },
        //       {
        //           "id": 35116,
        //           "name": "F-53 Motorhome Stripped Chassis"
        //       },
        //       {
        //           "id": 35117,
        //           "name": "F-59 Commercial Stripped Chassis"
        //       },
        //       {
        //           "id": 34103,
        //           "name": "F-650 Pro Loader"
        //       },
        //       {
        //           "id": 34104,
        //           "name": "F-650 Pro Loader Gas"
        //       },
        //       {
        //           "id": 34105,
        //           "name": "F-650 Straight Frame"
        //       },
        //       {
        //           "id": 34106,
        //           "name": "F-650 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34107,
        //           "name": "F-650 Tractor"
        //       },
        //       {
        //           "id": 34108,
        //           "name": "F-750 Straight Frame"
        //       },
        //       {
        //           "id": 34109,
        //           "name": "F-750 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34110,
        //           "name": "F-750 Tractor"
        //       },
        //       {
        //           "id": 34705,
        //           "name": "Maverick"
        //       },
        //       {
        //           "id": 35213,
        //           "name": "Mustang"
        //       },
        //       {
        //           "id": 35133,
        //           "name": "Mustang Mach-E"
        //       },
        //       {
        //           "id": 34903,
        //           "name": "Police Interceptor Utility"
        //       },
        //       {
        //           "id": 35138,
        //           "name": "Ranger"
        //       },
        //       {
        //           "id": 34313,
        //           "name": "Super Duty F-250 SRW"
        //       },
        //       {
        //           "id": 34304,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34314,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34305,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34315,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34303,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34326,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34311,
        //           "name": "Super Duty F-550 DRW"
        //       },
        //       {
        //           "id": 34309,
        //           "name": "Super Duty F-600 DRW"
        //       },
        //       {
        //           "id": 34439,
        //           "name": "Transit Cargo Van"
        //       },
        //       {
        //           "id": 34751,
        //           "name": "Transit Chassis"
        //       },
        //       {
        //           "id": 34230,
        //           "name": "Transit Connect Van"
        //       },
        //       {
        //           "id": 34231,
        //           "name": "Transit Connect Wagon"
        //       },
        //       {
        //           "id": 34440,
        //           "name": "Transit Crew Van"
        //       },
        //       {
        //           "id": 34750,
        //           "name": "Transit Cutaway"
        //       },
        //       {
        //           "id": 34469,
        //           "name": "Transit Passenger Wagon"
        //       }
        //     ],
        //     "Trim": [],
        //     ImageUrl: 'https://www6.qaautonation.com/voi/image/2017/Audi/A5%20Cabriolet',
        //   }
        //   {
        //     VehicleId: '31',
        //     Make: 'Ford',
        //     Model: 'Escape 31',
        //     Year: '2022',
        //     models: [
        //       {
        //           "id": 35192,
        //           "name": "Bronco"
        //       },
        //       {
        //           "id": 35070,
        //           "name": "Bronco Sport"
        //       },
        //       {
        //           "id": 34022,
        //           "name": "E-Series Cutaway"
        //       },
        //       {
        //           "id": 34023,
        //           "name": "E-Series Stripped Chassis"
        //       },
        //       {
        //           "id": 34441,
        //           "name": "E-Transit Cargo Van"
        //       },
        //       {
        //           "id": 34752,
        //           "name": "E-Transit Chassis"
        //       },
        //       {
        //           "id": 34753,
        //           "name": "E-Transit Cutaway"
        //       },
        //       {
        //           "id": 34789,
        //           "name": "EcoSport"
        //       },
        //       {
        //           "id": 34851,
        //           "name": "Edge"
        //       },
        //       {
        //           "id": 34526,
        //           "name": "Escape"
        //       },
        //       {
        //           "id": 34795,
        //           "name": "Expedition"
        //       },
        //       {
        //           "id": 34894,
        //           "name": "Expedition Max"
        //       },
        //       {
        //           "id": 34788,
        //           "name": "Explorer"
        //       },
        //       {
        //           "id": 35085,
        //           "name": "F-150"
        //       },
        //       {
        //           "id": 35373,
        //           "name": "F-150 Lightning"
        //       },
        //       {
        //           "id": 35129,
        //           "name": "F-150 Police Responder"
        //       },
        //       {
        //           "id": 35156,
        //           "name": "F-150 Special Service Vehicle"
        //       },
        //       {
        //           "id": 35116,
        //           "name": "F-53 Motorhome Stripped Chassis"
        //       },
        //       {
        //           "id": 35117,
        //           "name": "F-59 Commercial Stripped Chassis"
        //       },
        //       {
        //           "id": 34103,
        //           "name": "F-650 Pro Loader"
        //       },
        //       {
        //           "id": 34104,
        //           "name": "F-650 Pro Loader Gas"
        //       },
        //       {
        //           "id": 34105,
        //           "name": "F-650 Straight Frame"
        //       },
        //       {
        //           "id": 34106,
        //           "name": "F-650 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34107,
        //           "name": "F-650 Tractor"
        //       },
        //       {
        //           "id": 34108,
        //           "name": "F-750 Straight Frame"
        //       },
        //       {
        //           "id": 34109,
        //           "name": "F-750 Straight Frame Gas"
        //       },
        //       {
        //           "id": 34110,
        //           "name": "F-750 Tractor"
        //       },
        //       {
        //           "id": 34705,
        //           "name": "Maverick"
        //       },
        //       {
        //           "id": 35213,
        //           "name": "Mustang"
        //       },
        //       {
        //           "id": 35133,
        //           "name": "Mustang Mach-E"
        //       },
        //       {
        //           "id": 34903,
        //           "name": "Police Interceptor Utility"
        //       },
        //       {
        //           "id": 35138,
        //           "name": "Ranger"
        //       },
        //       {
        //           "id": 34313,
        //           "name": "Super Duty F-250 SRW"
        //       },
        //       {
        //           "id": 34304,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34314,
        //           "name": "Super Duty F-350 DRW"
        //       },
        //       {
        //           "id": 34305,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34315,
        //           "name": "Super Duty F-350 SRW"
        //       },
        //       {
        //           "id": 34303,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34326,
        //           "name": "Super Duty F-450 DRW"
        //       },
        //       {
        //           "id": 34311,
        //           "name": "Super Duty F-550 DRW"
        //       },
        //       {
        //           "id": 34309,
        //           "name": "Super Duty F-600 DRW"
        //       },
        //       {
        //           "id": 34439,
        //           "name": "Transit Cargo Van"
        //       },
        //       {
        //           "id": 34751,
        //           "name": "Transit Chassis"
        //       },
        //       {
        //           "id": 34230,
        //           "name": "Transit Connect Van"
        //       },
        //       {
        //           "id": 34231,
        //           "name": "Transit Connect Wagon"
        //       },
        //       {
        //           "id": 34440,
        //           "name": "Transit Crew Van"
        //       },
        //       {
        //           "id": 34750,
        //           "name": "Transit Cutaway"
        //       },
        //       {
        //           "id": 34469,
        //           "name": "Transit Passenger Wagon"
        //       }
        //     ],
        //     "Trim": [],
        //     ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/Dodge/Charger',
        //   },
        //   {
        //     VehicleId: 'q32',
        //     Make: 'Ford',
        //     Model: 'Escape 32',
        //     Year: '2022',
        //     "Trim": [
        //       {
        //           "id": 442087,
        //           "name": "Premium 50 quattro"
        //       },
        //       {
        //           "id": 442088,
        //           "name": "Premium Plus 50 quattro"
        //       },
        //       {
        //           "id": 442089,
        //           "name": "Prestige 50 quattro"
        //       }
        //   ],
        //     ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/FIAT/500X',
        //   },
        //   {
        //     VehicleId: '32',
        //     Make: 'Ford',
        //     Model: 'Escape',
        //     Year: '2022',
        //     "Trim": [
        //       {
        //           "id": 442087,
        //           "name": "Premium 50 quattro"
        //       },
        //       {
        //           "id": 442088,
        //           "name": "Premium Plus 50 quattro"
        //       },
        //       {
        //           "id": 442089,
        //           "name": "Prestige 50 quattro"
        //       }
        //   ],
        //     ImageUrl: 'http://media.chromedata.com/MediaGallery/media/MjcyNzMxXk1lZGlhIEdhbGxlcnk/E-gyFz8DbRGBMnVLdI_UUAKCrd4n157GtGaqkiZUYFxdL2eUTiiYzc2Ay-HLEKrE3eGaWIyOV49QaJt8c0FnD4AxRreTs15EECJMfHtzfjpHJpupjuP_NRwO4-w8_FInZO_ip4IBmHPt7R0QxE0uXGa55UXU6cl3TQDLGktGV6E/cc_2019HOC010076_01_320_GY.png',
        //   },
        //   {
        //      Make: 'Ford1',
        //      VehicleId: '2',
        //      Model: 'Escape1',
        //      Year: '2021',
        //      "Trim": [
        //       {
        //           "id": 442087,
        //           "name": "Premium 50 quattro"
        //       },
        //       {
        //           "id": 442088,
        //           "name": "Premium Plus 50 quattro"
        //       },
        //       {
        //           "id": 442089,
        //           "name": "Prestige 50 quattro"
        //       }
        //   ],
        //      ImageUrl: 'https://www6.qaautonation.com/voi/image/2021/FIAT/500X',
        //    }
      ],
      addThisVehicle: 'Add This Vehicle',
      remove: 'Remove This Vehicle',
      ask: 'Ask Me Later',
      done: 'Done',
      header: 'Add a Vehicle to Your Account',
      subHeader: 'Good news! These vehicles are connected to your email address. By adding any of them to your account, you can easily stay on top of maintenance, appointments, trade-in offers, and exciting features.',

    };

    this.addVehicleModalService.triggerAddVehicleModal(mockData).subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);

    });

  }

  openFinishAddVehicleModal() {

    const mockData = {
      isFinishAddingFlow: true,
      finishVehicle: {
        selectedSeatColor: 'Silver',
        selectedSeatMaterial: 'Cloth',
        givenNickName: 'adasd',
        selectedExteriorColor: 'Grigio Ferro Metallic',
        VehicleId: 'aec56c31-91f9-4851-9886-a535baf42dab',
        Make: 'Ford',
        Model: 'Escape',
        Year: '2022',
        models: [
          {
            "id": 35192,
            "name": "Bronco"
          },
          {
            "id": 35070,
            "name": "Bronco Sport"
          },
          {
            "id": 34022,
            "name": "E-Series Cutaway"
          },
          {
            "id": 34023,
            "name": "E-Series Stripped Chassis"
          },
          {
            "id": 34441,
            "name": "E-Transit Cargo Van"
          },
          {
            "id": 34752,
            "name": "E-Transit Chassis"
          },
          {
            "id": 34753,
            "name": "E-Transit Cutaway"
          },
          {
            "id": 34789,
            "name": "EcoSport"
          },
          {
            "id": 34851,
            "name": "Edge"
          },
          {
            "id": 34526,
            "name": "Escape"
          },
          {
            "id": 34795,
            "name": "Expedition"
          },
          {
            "id": 34894,
            "name": "Expedition Max"
          },
          {
            "id": 34788,
            "name": "Explorer"
          },
          {
            "id": 35085,
            "name": "F-150"
          },
          {
            "id": 35373,
            "name": "F-150 Lightning"
          },
          {
            "id": 35129,
            "name": "F-150 Police Responder"
          },
          {
            "id": 35156,
            "name": "F-150 Special Service Vehicle"
          },
          {
            "id": 35116,
            "name": "F-53 Motorhome Stripped Chassis"
          },
          {
            "id": 35117,
            "name": "F-59 Commercial Stripped Chassis"
          },
          {
            "id": 34103,
            "name": "F-650 Pro Loader"
          },
          {
            "id": 34104,
            "name": "F-650 Pro Loader Gas"
          },
          {
            "id": 34105,
            "name": "F-650 Straight Frame"
          },
          {
            "id": 34106,
            "name": "F-650 Straight Frame Gas"
          },
          {
            "id": 34107,
            "name": "F-650 Tractor"
          },
          {
            "id": 34108,
            "name": "F-750 Straight Frame"
          },
          {
            "id": 34109,
            "name": "F-750 Straight Frame Gas"
          },
          {
            "id": 34110,
            "name": "F-750 Tractor"
          },
          {
            "id": 34705,
            "name": "Maverick"
          },
          {
            "id": 35213,
            "name": "Mustang"
          },
          {
            "id": 35133,
            "name": "Mustang Mach-E"
          },
          {
            "id": 34903,
            "name": "Police Interceptor Utility"
          },
          {
            "id": 35138,
            "name": "Ranger"
          },
          {
            "id": 34313,
            "name": "Super Duty F-250 SRW"
          },
          {
            "id": 34304,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34314,
            "name": "Super Duty F-350 DRW"
          },
          {
            "id": 34305,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34315,
            "name": "Super Duty F-350 SRW"
          },
          {
            "id": 34303,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34326,
            "name": "Super Duty F-450 DRW"
          },
          {
            "id": 34311,
            "name": "Super Duty F-550 DRW"
          },
          {
            "id": 34309,
            "name": "Super Duty F-600 DRW"
          },
          {
            "id": 34439,
            "name": "Transit Cargo Van"
          },
          {
            "id": 34751,
            "name": "Transit Chassis"
          },
          {
            "id": 34230,
            "name": "Transit Connect Van"
          },
          {
            "id": 34231,
            "name": "Transit Connect Wagon"
          },
          {
            "id": 34440,
            "name": "Transit Crew Van"
          },
          {
            "id": 34750,
            "name": "Transit Cutaway"
          },
          {
            "id": 34469,
            "name": "Transit Passenger Wagon"
          }
        ],
        "Trim": [],
        ImageUrl: 'https://www6.qaautonation.com/voi/image/2024/Audi/A4%20Sedan',
        "exteriorColors": [
          "Bright White Clearcoat",
          "Ceramic Grey Clearcoat",
          "Gloss Black",
          "Granite Crystal Metallic Clearcoat",
          "Maximum Steel Metallic Clearcoat",
          "Ocean Blue Metallic Clearcoat",
          "Silver Mist Clearcoat",
          "Velvet Red Pearlcoat"
        ],
        "interiorColors": [
          "Beige",
          "Gray",
          "Black",
          "Tan",
          "Blue",
          "Brown",
          "Gold",
          "Green",
          "Maroon",
          "Orange",
          "Platinum",
          "Purple",
          "Red",
          "Silver",
          "Teal",
          "White",
          "Yellow"
        ],
        SeatMaterial: [
          {
            value: "Leather", description: "Leather"
          },
          {
            value: "Cloth", description: "Cloth"
          },
          {
            value: "Vinyl", description: "Vinyl"
          }
        ],
        estimatedMileage: 121323
      },
      addThisVehicle: 'Add This Vehicle',
      remove: 'Remove This Vehicle',
      ask: 'Ask Me Later',
      done: 'Done',
      header: 'Finish Adding Your Vehicle',
      subHeader: 'Adding a vehicle to your account allows you to keep up with your vehicle’s maintenance, create appointments, get a certified trade-in offer, and much more.',
      finishAddingLabelData: {
        requiredField: '*Indicates required field',
        VehicleNickname: 'Vehicle Nickname',
        AddNickName: 'Add a Nickname',
        ExteriorColor: 'Exterior Color',
        SeatColor: 'Seat Color',
        SeatMaterial: 'Seat Material',
        EstimatedMileage: 'Esitmated Mileage',
        AddMileage: 'Add Mileage',
      }
    };

    this.addVehicleModalService.triggerAddVehicleModal(mockData).subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);

    });

  }

  openModalConfirm() {
    const modal = this.modalService.openConfirm({
      header: "Confirm Cancel Appointment",
      subText: "Are you sure you want to cancel your appointment?",
      buttonText: "No, Go Back",
      buttonText2: "Yes, Cancel my Appointment"
    });

    modal.onClickButton2.subscribe((_: any) => {
      console.log('do something...');
    });
  }


  openAuthModalAppointment(source?: string) {
    this.authModalService.triggerAuthModal(source).subscribe((doAuthOnClosing: any) => {
      if (doAuthOnClosing) {
        console.log('Navigate to Authentication Page with', doAuthOnClosing, 'mode');
        try {
          if ((window as any).auth) {
            (window as any).auth.loginWithAuth0(doAuthOnClosing, 'mg=f');
          }
        } catch (e) {/* istanbul ignore next */
          console.log(e);
        }
      }
    });
  }

  openAuthModalSRPSearch(source?: string) {
    this.authModalService.triggerAuthModal(source).subscribe((doAuthOnClosing: any) => {
      /* istanbul ignore if */
      if (doAuthOnClosing) {
        console.log('Navigate to Authentication Page with', doAuthOnClosing, 'mode');
        try {
          if ((window as any).loginWithAuth0) {
            /* await */
            (window as any).loginWithAuth0(doAuthOnClosing, true, 'mg=f');
          }

        } catch (e) {/* istanbul ignore next */
          console.log(e);
        }
      }
    });
  }

  openNotificationModal(isApp?: boolean) {

    document.dispatchEvent(
      new CustomEvent("__OPEN__NOTIFICATION__MODAL__", {
        detail: 'hello',
      })
    );
  }

  openLeadFormModal() {
    const data = {
      "id": "9abee604-eec1-11ef-bd2e-6045bdbb9574",
      "name": "Most New 2025 RAM 1500 Models",
      "description": "",
      "disclaimer": "4.9% APR financing for 72 months equals $16.06 a month per every $1000 financed for well-qualified buyers through Stellantis Financial Services regardless of down payment.  Offer available on New 2025 Ram 1500 models (excluding RHO Models) Cannot be combined with other incentives. See store for complete details. Offer expires end of month.",
      "vehicles": [
          {
              "stockType": "new",
              "year": 2025,
              "make": "Ram",
              "model": "",
              "bodyType": "",
              "fuelType": ""
          }
      ],
      "stores": [
          {
              "hyperionId": "2940",
              "name": "AutoNation Chrysler Dodge Jeep Ram Pembroke Pines",
              "address": "13601 Pines Boulevard  Pembroke Pines FL",
              "zipCode": "33027",
              "distance": 0.84,
              "priority": 1
          }
      ],
      "startDate": "2025-02-19T13:01:18",
      "expirationDate": "2025-03-03T00:00:00",
      "imageUrl": "https://anspecials.kreateservices.com/vehicles/2025_RAM_Truck-(639x359).jpg",
      "vehicleOfferDetails": [
          [
              {
                  "type": 2,
                  "customText": "",
                  "leaseMonthlyPrice": 0,
                  "leaseTerm": 0,
                  "financeTerm": 72,
                  "leaseTotalDue": 0,
                  "title": "",
                  "description": "",
                  "financeApr": 4.9,
                  "purchaseAmount": 0
              }
          ]
      ],
      "promotionalOfferDetails": null,
      "serviceOfferDetails": null,
      "closestDistance": 0.84,
      "categories": null
  }

    const data1 =  {
      "id": "789d379d-eec1-11ef-bd2e-6045bdbb9574",
      "name": "Giving back to those who give their all",
      "description": "Cash Allowance on Chevrolet Models",
      "disclaimer": "$500 cash allowance available for eligible military personnel. Includes Active Duty members, Reservists, National Guard members, Veterans and Retirees of the U.S. Army, Navy, Air Force, Marine Corps and Coast Guard. Eligible participants are able to sponsor their spouse. Excludes select base trims. Not available with some other offers. See store for complete details. Offer expires 1/2/26.",
      "vehicles": [
          {
              "stockType": "new",
              "year": 2025,
              "make": "Chevrolet",
              "model": "",
              "bodyType": "",
              "fuelType": ""
          }
      ],
      "stores": [
          {
              "hyperionId": "2461",
              "name": "AutoNation Chevrolet Coral Gables",
              "address": "4181 SW 8th Street  Miami FL",
              "zipCode": "33134",
              "distance": 16.57,
              "priority": 1
          },
          {
              "hyperionId": "2334",
              "name": "AutoNation Chevrolet Doral",
              "address": "1201 NW 89th Court  Miami FL",
              "zipCode": "33172",
              "distance": 14.79,
              "priority": 1
          },
          {
              "hyperionId": "2269",
              "name": "AutoNation Chevrolet Pembroke Pines",
              "address": "8600 Pines Boulevard  Pembroke Pines FL",
              "zipCode": "33024",
              "distance": 4.21,
              "priority": 1
          }
      ],
      "startDate": "2025-02-19T13:00:22",
      "expirationDate": "2026-01-02T00:00:00",
      "imageUrl": "https://anspecials.kreateservices.com/vehicles/2025_Chevrolet_Military-(639x359).jpg",
      "vehicleOfferDetails": [
          [
              {
                  "type": 3,
                  "customText": "",
                  "leaseMonthlyPrice": 0,
                  "leaseTerm": 0,
                  "financeTerm": 0,
                  "leaseTotalDue": 0,
                  "title": "",
                  "description": "",
                  "financeApr": 0,
                  "purchaseAmount": 500
              }
          ]
      ],
      "promotionalOfferDetails": null,
      "serviceOfferDetails": null,
      "closestDistance": 4.21,
      "categories": null
  }
    const data2 =  {
      "ImageURL": "https://anspecials.kreateservices.com/vehicles/2025_Land_Rover_Evoque_S-(639x359).jpg",
      "tileTitle": "New 2025 Range Rover Evoque S",
      "StoreName": "Jaguar Land Rover Fort Lauderdale",
      "disclaimer": "MSRP $49,990. MSRP + Freight + Options $53,968. 36-month lease. $6,990 due at signing includes $4,331 capitalized cost reduction, $0 security deposit, $1,075 acquisition fee, first month's payment, and $995 dealer fee; excludes retailer fees, taxes, title, license and registration fees. $0 Security Deposit. 10,000 miles per year ($0.30 per excess mile). With approved above-average credit through approved lender, JPMorgan Chase, N.A. See store for complete details. Offers expire 3/4/25.",
      "vehicleOfferDetails": [
        [
            {
                "type": 2,
                "customText": "",
                "leaseMonthlyPrice": 0,
                "leaseTerm": 0,
                "financeTerm": 72,
                "leaseTotalDue": 0,
                "title": "",
                "description": "",
                "financeApr": 4.9,
                "purchaseAmount": 0
            }
        ]
    ]
    }

    const data2a =  {
      "ImageURL": "https://anspecials.kreateservices.com/vehicles/2025_Land_Rover_Evoque_S-(639x359).jpg",
      "tileTitle": "New 2025 Range Rover Evoque S",
      "StoreName": "Jaguar Land Rover Fort Lauderdale",
      "disclaimer": "MSRP $49,990. MSRP + Freight + Options $53,968. 36-month lease. $6,990 due at signing includes $4,331 capitalized cost reduction, $0 security deposit, $1,075 acquisition fee, first month's payment, and $995 dealer fee; excludes retailer fees, taxes, title, license and registration fees. $0 Security Deposit. 10,000 miles per year ($0.30 per excess mile). With approved above-average credit through approved lender, JPMorgan Chase, N.A. See store for complete details. Offers expire 3/4/25.",
      "vehicleOfferDetails": [
        [
            {
                "type": 2,
                "customText": "",
                "leaseMonthlyPrice": 0,
                "leaseTerm": 0,
                "financeTerm": 72,
                "leaseTotalDue": 0,
                "title": "",
                "description": "",
                "financeApr": 4.9,
                "purchaseAmount": 0
            },
            {
              "type": 1,
              "customText": "",
              "leaseMonthlyPrice": 589,
              "leaseTerm": 36,
              "financeTerm": 0,
              "leaseTotalDue": 6990,
              "title": "",
              "description": "",
              "financeApr": 0,
              "purchaseAmount": 0
              }
        ]
    ]
    }

    const data3 =  {
      "ImageURL": "https://anspecials.kreateservices.com/vehicles/2024_Ford_Expedition-(639x359).jpg",
      "tileTitle": "New 2024 Ford Expedition Models",
      "description": "",
      "StoreName": "AutoNation Ford Margate",
      "disclaimer": "Example Expedition Max STK# REA56202. MSRP $73,595 - $7,000 OEM Rebates - $6,000 AutoNation Discount = $60,595. Price plus tax, tag, title, license, registration, government fees and $995 Dealer Service Fee. See store for complete details. Offer expires end of month.",
      "vehicleOfferDetails": [
        [ 
          {
            "type": 3,
            "customText": "",
            "leaseMonthlyPrice": 0,
            "leaseTerm": 0,
            "financeTerm": 0,
            "leaseTotalDue": 0,
            "title": "Up to",
            "description": "OFF MSRP",
            "financeApr": 0,
            "purchaseAmount": 13000
            }
        ]
    ]
    }
    const data12 =  {
      "id": "924619c0-eec1-11ef-bd2e-6045bdbb9574",
      "name": "New 2024 Toyota Tacoma SR5",
      "description": "",
      "disclaimer": "MDL #7146. Automatic. 1.99% APR Financing for a limited-term available on select new 2024 Tacoma and Tacoma Hybrid models. Down payment will vary with APR and credit. Includes $995 Dealer Fee. Not all buyers will qualify. With approved above average credit through Southeast Toyota Finance. 39 month lease. $7,863 plus taxes, tag, title, registration fees, government fees and tag agency/electronic filing fee due at signing. Due at signing includes $249 first payment, $6,619 capitalized cost reduction, $0 security deposit, and $995 dealer service fee. Must finance with SETF. With approved credit. 12k miles per year. $0.18 per excess mile due at lease end. Includes $1,750 Tacoma Bonus Lease Cash towards capitalized cost reduction, valid on 2024 Tacoma SR5 Models. Offers expire 3/3/2025.",
      "vehicles": [
      {
      "stockType": "new",
      "year": 2024,
      "make": "Toyota",
      "model": "Tacoma",
      "bodyType": "",
      "fuelType": ""
      },
      {
      "stockType": "",
      "year": 2024,
      "make": "Toyota",
      "model": "Tacoma",
      "bodyType": "",
      "fuelType": ""
      }
      ],
      "stores": [
      {
      "hyperionId": "2125",
      "name": "AutoNation Toyota Fort Myers",
      "address": "2555 Colonial Boulevard  Ft. Myers FL",
      "zipCode": "33907",
      "distance": 0,
      "priority": 1
      }
      ],
      "startDate": "2025-02-19T13:02:22",
      "expirationDate": "2025-03-03T00:00:00",
      "imageUrl": "https://anspecials.kreateservices.com/vehicles/2024_Toyota_Tacoma_SR5-(639x359).jpg",
      "vehicleOfferDetails": [
      [
      {
      "type": 1,
      "customText": "",
      "leaseMonthlyPrice": 249,
      "leaseTerm": 39,
      "financeTerm": 0,
      "leaseTotalDue": 7863,
      "title": "",
      "description": "",
      "financeApr": 0,
      "purchaseAmount": 0
      }
      ],
      [
      {
      "type": 2,
      "customText": "",
      "leaseMonthlyPrice": 0,
      "leaseTerm": 0,
      "financeTerm": 0,
      "leaseTotalDue": 0,
      "title": "",
      "description": "",
      "financeApr": 1.99,
      "purchaseAmount": 0
      }
      ]
      ],
      "promotionalOfferDetails": null,
      "serviceOfferDetails": null,
      "closestDistance": 0,
      "categories": null
      }
    const data23 =  {
      "id": "c86a291b-eec1-11ef-bd2e-6045bdbb9574",
      "hid": "2413",
      "sname": "Audi Volkswagen Spokane",
      "name": "New 2025 Volkswagen Atlas Models",
      "description": "",
      "disclaimer": "Example MSRP $42,225. VIN#1V2LR2CA2SC520381. Selling Price $40,225. 3.9% APR Financing for 60 months at $18.37 per month per $1,000 financed applies to select new 2025 Volkswagen Atlas models. Based on $0 down payment. Not all buyers will qualify. With approved credit for well-qualified buyers when financing Volkswagen Credit. $2,000 Retail Customer Bonus applied towards the purchase or lease of an eligible model. See stores for complete details. Cannot be combined with any other discounts or incentives. Offer expires 03/03/25.",
      "vehicles": [
      {
      "stockType": "",
      "year": 2025,
      "make": "Volkswagen",
      "model": "Atlas",
      "bodyType": "",
      "fuelType": ""
      }
      ],
      "stores": [
      {
      "hyperionId": "2413",
      "name": "Audi Volkswagen Spokane",
      "address": "10006 East Sprague Avenue  Spokane Valley WA",
      "zipCode": "99206",
      "distance": 0,
      "priority": 1
      }
      ],
      "startDate": "2025-02-19T13:02:33",
      "expirationDate": "2025-03-03T00:00:00",
      "imageUrl": "https://anspecials.kreateservices.com/vehicles/2025_Volkswagen_Atlas_SE-(639x359).jpg",
      "vehicleOfferDetails": [
      [
      {
      "type": 2,
      "customText": "",
      "leaseMonthlyPrice": 0,
      "leaseTerm": 0,
      "financeTerm": 60,
      "leaseTotalDue": 0,
      "title": "",
      "description": "",
      "financeApr": 3.9,
      "purchaseAmount": 0
      }
      ],
      [
      {
      "type": 3,
      "customText": "",
      "leaseMonthlyPrice": 0,
      "leaseTerm": 0,
      "financeTerm": 0,
      "leaseTotalDue": 0,
      "title": "",
      "description": "OFF MSRP",
      "financeApr": 0,
      "purchaseAmount": 2000
      }
      ]
      ],
      "promotionalOfferDetails": null,
      "serviceOfferDetails": null,
      "closestDistance": 0,
      "categories": null
      }

    const data3a =  {
      "id": "c86a291b-eec1-11ef-bd2e-6045bdbb9574",
      "name": "New 2025 Volkswagen Atlas Models",
      "description": "10006 East Sprague Avenue  Spokane Valley WA",
      "disclaimer": "Example MSRP $42,225. VIN#1V2LR2CA2SC520381. Selling Price $40,225. 3.9% APR Financing for 60 months at $18.37 per month per $1,000 financed applies to select new 2025 Volkswagen Atlas models. Based on $0 down payment. Not all buyers will qualify. With approved credit for well-qualified buyers when financing Volkswagen Credit. $2,000 Retail Customer Bonus applied towards the purchase or lease of an eligible model. See stores for complete details. Cannot be combined with any other discounts or incentives. Offer expires 03/03/25.",
      "vehicles": [
      {
      "stockType": "",
      "year": 2025,
      "make": "Volkswagen",
      "model": "Atlas",
      "bodyType": "",
      "fuelType": ""
      }
      ],
      "stores": [
      {
      "hyperionId": "2413",
      "name": "Audi Volkswagen Spokane",
      "address": "10006 East Sprague Avenue  Spokane Valley WA",
      "zipCode": "99206",
      "distance": 0,
      "priority": 1
      }
      ],
      "startDate": "2025-02-19T13:02:33",
      "expirationDate": "2025-03-03T00:00:00",
      "imageUrl": "https://anspecials.kreateservices.com/vehicles/2025_Volkswagen_Atlas_SE-(639x359).jpg",
      "vehicleOfferDetails": [
      [
      {
      "type": 2,
      "customText": "",
      "leaseMonthlyPrice": 0,
      "leaseTerm": 0,
      "financeTerm": 60,
      "leaseTotalDue": 0,
      "title": "",
      "description": "",
      "financeApr": 3.9,
      "purchaseAmount": 0
      },
      {
        "type": 3,
        "customText": "",
        "leaseMonthlyPrice": 0,
        "leaseTerm": 0,
        "financeTerm": 0,
        "leaseTotalDue": 0,
        "title": "",
        "description": "OFF MSRP",
        "financeApr": 0,
        "purchaseAmount": 2000
        }
      ]
      ],
      "promotionalOfferDetails": null,
      "serviceOfferDetails": null,
      "closestDistance": 0,
      "categories": null
      }

    document.dispatchEvent(
    new CustomEvent("__OPEN__OFFERLEADFORM__MODAL__", {
      detail: {
        data: data12,
      "HomeOfferLabelsData": {
          "HomeOfferAndText": "And",
          "HomeOfferFinanceNoText": "Financing Available",
          "HomeOfferFinancePostText": "Months",
          "HomeOfferFinancePreText": "Financing for",
          "HomeOfferStoresDisplayCount": 3,
          "HomeOfferTilesDisplayCount": 6,
          "HomeOfferLeadForm": {
              "StoreOfferLeaseText": "Lease / mo",
              "StoreOfferFinanceNoText": "Financing Available",
              "StoreOfferFinanceText": "APR",
              "StoreOfferFinancePostText": "Months",
              "LeaseOfferDueText": "due at signing",
              "LabelFormErrorMsg": "We encountered an error on our end. Please try again shortly.",
              "SubmitCTA": "Inquire About This Deal",
              "OfferLeadForm": {
                  "LeadformTitle": "Interested in this deal?",
                  "LeadformSubTitle": "Simply complete the form and we will be in touch.",
                  "RequiredFieldLabel": "*Indicates required field",
                  "FirstNameLabel": "First Name*",
                  "FirstNamePlaceholder": "ex: John",
                  "FirstNameRequiredError": "Please enter your first name",
                  "FirstNameInvalidError": "First name must be more than 2 characters",
                  "LastNameLabel": "Last Name*",
                  "LastNamePlaceholder": "ex: Smith",
                  "LastNameRequiredError": "Please enter your last name",
                  "LastNameInvalidError": "Last name must be more than 2 characters",
                  "EmailLabel": "Email Address*",
                  "EmailPlaceholder": "ex: john@email.com",
                  "EmailRequiredError": "Please enter your email",
                  "EmailInvalidError": "Please enter a valid email",
                  "EmailTooltipTitle": "Email Address",
                  "EmailTooltipInfo": "Forms submitted while you are logged in are tied to your account. If you would like to change the email address used in this form, please log out and try again.",
                  "PhoneLabel": "Phone Number*",
                  "PhonePlaceholder": "(___) ___ - ____",
                  "PhoneRequiredError": "Enter phone number",
                  "PhoneInvalidError": "Please enter a valid phone number",
                  "ZipCodeLabel": "ZIP Code*",
                  "ZipCodePlaceholder": "ex: 90210",
                  "ZipCodeRequiredError": "Enter valid ZIP Code",
                  "ZipCodeInvalidError": "Enter valid ZIP Code",
                  "FirstNameLength": 50,
                  "LastNameLength": 60
              }
          }
      },
      TealiumSettings: {
        TealiumAccount: "autonation",
        TealiumENV: "qa",
        TealiumProfile: "sitecorean"
      }},
      })
    );
  }

  openVerifyEmailModal() {
    this.verifyEmailModalService.triggerVerifyEmailModal().subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);
    });
  }

  openSRPVerifyEmailModal() {
    this.verifyEmailModalService.triggerVerifyEmailModal('srp-search').subscribe((doAuthOnClosing: any) => {
      console.log('Return Data - ', doAuthOnClosing);
    });
  }

}

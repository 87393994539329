<form [formGroup]="appointmentFormGroup" class="an-appointment">

  <an-appt-date
    [dateTitle]="dateTitle"
    [dateControl]="dateControl"
    [dates]="dates"
    (onChangeDate)="onChangeDate($event)">
  </an-appt-date>

  <an-appt-time 
    [timeControl]="timeControl"
    [hours]="hours"
    [timeTitle]="timeTitle">
  </an-appt-time>

  <hr>

  <an-input-field
    [label]="leadFormLabels.firstName.label"
    [placeholder]="leadFormLabels.firstName.placeholder"
    [requiredErrorMessage]="leadFormLabels.firstName.errors.required"
    [inputControl]="firstNameControl"  
    [maxlength]="50"
    [invalidCharacters]="'!@#$%^&*'">
  </an-input-field>

  <an-input-field
    [label]="leadFormLabels.lastName.label"
    [placeholder]="leadFormLabels.lastName.placeholder"
    [requiredErrorMessage]="leadFormLabels.lastName.errors.required"
    [inputControl]="lastNameControl"
    mask="S*" >
  </an-input-field>

  <an-input-field
    [label]="leadFormLabels.phone.label"
    [placeholder]="leadFormLabels.phone.placeholder"
    [requiredErrorMessage]="leadFormLabels.phone.errors.required"
    [invalidErrorMessage]="leadFormLabels.phone.errors.invalid"
    [inputControl]="phoneControl"
    mask="(000) 000-0000">
  </an-input-field>

  <an-input-field
    [label]="leadFormLabels.zipCode.label"
    [placeholder]="leadFormLabels.zipCode.placeholder"
    [requiredErrorMessage]="leadFormLabels.zipCode.errors.required"
    [invalidErrorMessage]="leadFormLabels.zipCode.errors.invalid"
    [inputControl]="zipCodeControl"
    mask="00000">
  </an-input-field>
  
  <an-input-email
    [label]="leadFormLabels.email.label"
    [placeholder]="leadFormLabels.email.placeholder" 
    [requiredErrorMessage]="leadFormLabels.email.errors.required"
    [invalidErrorMessage]="leadFormLabels.email.errors.invalid"
    [emailControl]="emailControl"
    [tooltipTitle]="'Email address'"
    [tooltipContent]="'Forms submitted while you are logged in are tied to your account. If you would like to change the email address used in this form, please log out and try again.'"    
    (onClickHelp)="clickHelp()">
  </an-input-email>    

  
  <an-input-state
    [stateControl]="stateControl"
    [states]="states"
    [stateLabel]="'State*'">
  </an-input-state>


  <br>
  <div class="an-button-container">
    <an-custom-button
      [label]="myButtonLabel"
      [buttonStyles]="myButtonStyles"
      [labelStyles]="myLabelStyles"
      (onButtonClick)="myButtonClick()">
    </an-custom-button>
  </div>

  <br>
  <div class="an-button-container">
    <an-custom-button
      [label]="myButtonLabel"
      [buttonStyles]="myButtonStylesWIthIcon"
      [labelStyles]="myLabelStylesWithIcon"
      (onButtonClick)="myButtonWithIconClick()">

      <span class="an-button-container__icon"></span>

    </an-custom-button>
  </div>

  <hr>

  <an-textarea-field [label]="leadFormLabels.disclaimer.label" [placeholder]="leadFormLabels.disclaimer.placeholder"
    [requiredErrorMessage]="leadFormLabels.disclaimer.requiredErrorMessage" [textareaControl]="disclaimerControl"
    [maxlength]="1000" [rows]="3">
  </an-textarea-field>

  <br>  

  <an-input-field 
    [label]="leadFormLabels.price.label" 
    [placeholder]="leadFormLabels.price.placeholder"
    [requiredErrorMessage]="leadFormLabels.price.requiredErrorMessage" 
    [inputControl]="priceControl" 
    mask="separator.2" thousandSeparator=".">
  </an-input-field>


  <br><br>

</form>
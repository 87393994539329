<button *ngIf="!newIcon" [attr.aria-label]="(state ? 'Saved ' : 'Save ') + ariaLabel" class="an-heart-icon" [id]="'save-vin-' + id" [ngClass]="{'on': state}" (click)="clickHeart()" (keyup.enter)="clickHeart()">
  <svg [attr.aria-label]="(state ? 'Saved ' : 'Save ') + ariaLabel" class="an-svg" viewBox="0 0 30 30" role="img"  [ngStyle]="{ width: width, height: height }">
    <use class="an-svg-use an-svg-use-fill" xlink:href="#heart" />
    <use class="an-svg-use an-svg-use-outline" xlink:href="#heart" />
  </svg>

  <svg class="an-svg an-hide" viewBox="0 0 30 30">
    <defs>
      <path id="heart"
        d="M15,4.875A8.224,8.224,0,0,0,7.5,0,7.265,7.265,0,0,0,0,7.312C0,13.047,6.322,17.482,15,26c8.678-8.518,15-12.953,15-18.688A7.265,7.265,0,0,0,22.5,0,8.22,8.22,0,0,0,15,4.875Z" />
    </defs>
  </svg>
</button>

<button [attr.aria-label]="(state ? 'Saved ' : 'Save ') + ariaLabel" *ngIf="newIcon && !newVdpIcon" class="an-heart-icon-new " [id]="'save-vin-' + id" [ngClass]="{'on': state}" (click)="clickHeart()" (keyup.enter)="clickHeart()">
  <svg role="img" [attr.aria-label]="(state ? 'Saved ' : 'Save ') + ariaLabel" class="an-svg" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{ width: width, height: height }" viewBox="0 0 24 20" fill="none">
    <g filter="url(#filter0_b_8285_799)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5115 1.43423C15.2559 1.14751 16.0542 1 16.8605 1C17.6667 1 18.4651 1.14751 19.2104 1.43423C19.9548 1.72094 20.6314 2.14111 21.2019 2.67085C21.7715 3.20022 22.2242 3.82884 22.5326 4.52068C22.8409 5.21252 23 5.95416 23 6.70311C23 7.45205 22.8409 8.1937 22.5326 8.88554C22.2242 9.57747 21.7715 10.2061 21.201 10.7355L12.52 18.7996C12.2332 19.0668 11.7668 19.0668 11.479 18.7996L2.79806 10.7355C1.64616 9.66605 1 8.2155 1 6.70311C1 5.19071 1.64616 3.74017 2.79806 2.67076C3.94898 1.60125 5.51036 1.00046 7.13853 1.00046C8.76671 1.00046 10.3281 1.60125 11.479 2.67076L11.9995 3.15388L12.52 2.67085C13.0895 2.14111 13.7662 1.72094 14.5115 1.43423Z" fill="E61464" fill-opacity="0.5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5115 1.43423C15.2559 1.14751 16.0542 1 16.8605 1C17.6667 1 18.4651 1.14751 19.2104 1.43423C19.9548 1.72094 20.6314 2.14111 21.2019 2.67085C21.7715 3.20022 22.2242 3.82884 22.5326 4.52068C22.8409 5.21252 23 5.95416 23 6.70311C23 7.45205 22.8409 8.1937 22.5326 8.88554C22.2242 9.57747 21.7715 10.2061 21.201 10.7355L12.52 18.7996C12.2332 19.0668 11.7668 19.0668 11.479 18.7996L2.79806 10.7355C1.64616 9.66605 1 8.2155 1 6.70311C1 5.19071 1.64616 3.74017 2.79806 2.67076C3.94898 1.60125 5.51036 1.00046 7.13853 1.00046C8.76671 1.00046 10.3281 1.60125 11.479 2.67076L11.9995 3.15388L12.52 2.67085C13.0895 2.14111 13.7662 1.72094 14.5115 1.43423Z" stroke="#E61464" stroke-width="1.40379"/>
    </g>
    <use class="an-svg-use an-svg-use-fill" xlink:href="#newheart" />
    <use class="an-svg-use an-svg-use-outline" xlink:href="#newheart" />
    </svg>
    <svg class="an-svg an-hide" viewBox="0 0 24 20">
      <defs>
        <path id="newheart" fill-rule="evenodd" clip-rule="evenodd" d="M14.5115 1.43423C15.2559 1.14751 16.0542 1 16.8605 1C17.6667 1 18.4651 1.14751 19.2104 1.43423C19.9548 1.72094 20.6314 2.14111 21.2019 2.67085C21.7715 3.20022 22.2242 3.82884 22.5326 4.52068C22.8409 5.21252 23 5.95416 23 6.70311C23 7.45205 22.8409 8.1937 22.5326 8.88554C22.2242 9.57747 21.7715 10.2061 21.201 10.7355L12.52 18.7996C12.2332 19.0668 11.7668 19.0668 11.479 18.7996L2.79806 10.7355C1.64616 9.66605 1 8.2155 1 6.70311C1 5.19071 1.64616 3.74017 2.79806 2.67076C3.94898 1.60125 5.51036 1.00046 7.13853 1.00046C8.76671 1.00046 10.3281 1.60125 11.479 2.67076L11.9995 3.15388L12.52 2.67085C13.0895 2.14111 13.7662 1.72094 14.5115 1.43423Z" stroke="#E61464" stroke-width="1.40379"/>
        </defs>
    </svg>
</button>